<template>
	<div class="body">
		<div class="nav">
			<el-link href="/" :underline="false">{{$t('userCenter.indexNav')}} <span style="margin: 0 10px;">/</span></el-link>
			<el-link href="/message" :underline="false" style="color: #000000;">{{$t('message.message')}}</el-link>
		</div>
		<div class="wrap">
			<div class="wrap-left">
				<el-row class="tac">
					<el-col :span="13">
						<el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" style="border: none;text-align: center;width:200px;">
							<el-submenu :index="index.toString()" v-for="(item,index) in nav" v-if="item.subMenu.length > 0" :key="index">
								<template slot="title">
									<i :class="item.icon"></i>
									<span class="font-size">{{$lang(item,"text")}}</span>
								</template>
								<el-menu-item-group v-for="(submenu,key) in item.subMenu" :key="key">
									<template slot="title">{{submenu.text}}</template>
								</el-menu-item-group>
							</el-submenu>
							<el-menu-item :index="index.toString()" v-else>
								<i :class="item.icon"></i>
								<span slot="title"><el-link :href="item.link" class="font-size" :underline="false" :class = "item.active">{{$lang(item,"text")}}</el-link></span>
							</el-menu-item>
						</el-menu>
					</el-col>
				</el-row> 
			</div>
			<div class="wrap-right">
				<div v-for="(item,index) in message" style="border-bottom: 1px solid #585858;">
					<div class="title">
						<span>{{ $lang(item,"title") }}</span>
						<span class="title-time">{{item.createTime}}</span>
					</div>
					<div class="content"><p><span class="content-notice"><{{$t("message.platformNotice")}}></span>{{ $lang(item,"content" )}}</p></div>
					<div v-if="item.type == 2" class="des-reply">
						<div class="des">{{ $lang(item,"des") }}</div>
						<div class="reply"><el-link href="#" target="_blank" :underline="false">{{ $t("message.reply") }}</el-link></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		data(){
			return{
				nav:
				[
					{
						icon:'',
						textZhCn:'全部',
						textEnUs:'All',
						link:'#',
						subMenu:[],
						active:'active',
					},
					{
						icon:'',
						textZhCn:'公告',
						textEnUs:'Notice',
						link:'#',
						subMenu:[],
						active:'',
					},
					{
						icon:'',
						textZhCn:'创意工坊',
						textEnUs:'Creative Workshop',
						link:'#',
						subMenu:[],
						active:'',
					},
				],
				message:[
					{
						titleZhCn:'公告',
						titleEnUs:'Notice',
						contentZhCn:'',
						contentEnUs:'',
						desZhCn:'',
						desEnUs:'',
						createTime:'2021-09-09 02:08:32',
						type:1
					},
					{
						titleZhCn:'公告',
						titleEnUs:'Notice',
						contentZhCn:'',
						contentEnUs:'',
						desZhCn:'',
						desEnUs:'',
						createTime:'2021-09-09 02:08:32',
						type:1
					},
					{
						titleZhCn:'创意工坊',
						titleEnUs:'Creative Workshop',
						contentZhCn:'',
						contentEnUs:'',
						desZhCn:'',
						desEnUs:'',
						createTime:'2021-09-09 02:08:32',
						type:2
					},
					
				]
			}
		},methods:{
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			navTo(url){
				this.$router.push(url);
			
			}
			
		}
	}
</script>
<style scoped>
	.body{
		background-color: #eee;
		width: 100%;
		height:100%;
		min-height:1080px;
	}
	.nav{
		margin: 0 auto;
		width:1200px;
		margin-top: 40px;
		padding:20px 0;
		
	}
	.nav span{
		marign:0px 10px;
	}
	.el-link{
		font-size: 16px;
		font-weight: 600;
	}
	.wrap{
		margin: 0 auto;
		width:1200px;
		display: flex;
		justify-content: space-between;
	}
	.wrap-left{
		width:20%;
		height:800px;
		background-color: #fff;
		padding:20px 15px 40px;
	}
	.wrap-right{
		width:78%;
		height:800px;
		background-color: #fff;
		padding:40px;
		font-size: 14px;
	}
	.font-size{
		letter-spacing:1px;
		color:#888;
		font-weight: 400;
		font-size: 16px;
	}
	.title{
		font-size: 20px;
		padding: 20px 0;
	}
	.title span{
		font-weight: 600;
		color:#585858;
	}
	.title-time{
		font-size:18px;
		font-weight: 400;
		margin-left: 20px;
		color:#bfbfbf;
	}
	.content{
		font-weight: 400;
		color:#585858;
		padding-bottom: 20px;
	}
	.content-notice{
		font-size: 16px;
		font-weight: 400;
		color:#585858
	}
	.des-reply{
		display: flex;
		justify-content: space-between;
		padding:20px 0;
		font-size: 18px;
		color:#585858;
	}
	.des{
		width:800px;
		background-color: #e5e5e5;
	}
	.reply{
		text-align: center;
		font-weight: 600;
		
	}
</style>
